import { getAuthUser } from "./Auth";

interface Setting {
	value: string;
}

interface User {
	settings: Setting[];
}

/**
 * Converts a temperature value based on the user's settings.
 *
 * @param value - The temperature value to convert (could be in Celsius or Fahrenheit).
 * @returns The converted temperature or a placeholder '-' if the value or settings are invalid.
 */
function getTemperatureConversion(value: number | null | undefined): number | string {
	const user = getAuthUser();

	// Validate the input value
	if (value === null || value === undefined || !Number.isFinite(value)) {
		console.warn("Input value is invalid.");
		return "-"; // Return '-' if value is invalid
	}

	// Validate user settings
	if (!user || !user.settings || !user.settings[2]) {
		console.warn("User settings are not properly defined.");
		return "-"; // Return '-' if settings are invalid
	}

	const temp = user.settings[2]?.value;

	// Perform conversion based on the user's temperature scale
	if (temp === "fahrenheit") {
		// Convert Celsius to Fahrenheit
		return toFahrenheit(value);
	} else if (temp === "celsius") {
		// Convert Fahrenheit to Celsius
		return value;
	} else {
		console.warn('Invalid scale. Use "fahrenheit" or "celsius".');
		return "-"; // Return '-' for invalid scale
	}
}

/**
 * Converts a distance value based on the user's settings.
 *
 * @param value - The distance value to convert (could be in kilometers or miles).
 * @returns The converted distance or a placeholder '-' if the value or settings are invalid.
 */
function getDistanceConversion(value: number | null | undefined): number | string {
	const user = getAuthUser();
	// Validate the input value
	if (value === null || value === undefined || !Number.isFinite(value)) {
		console.warn("Input value is invalid.");
		return "-"; // Return '-' if value is invalid
	}

	// Validate user settings
	if (!user || !user.settings || !user.settings[3]) {
		console.warn("User settings are not properly defined.");
		return "-"; // Return '-' if settings are invalid
	}

	const dist = user.settings[3]?.value;
	// Perform conversion based on the user's distance scale
	if (dist === "metric") {
		// Convert kilometers to miles
		return value;
	} else if (dist === "kilometers") {
		// Convert miles to kilometers
		return toKilometers(value);
	} else {
		console.warn('Invalid distance scale. Use "metric" or "kilometers".');
		return "-"; // Return '-' for invalid scale
	}
}

/**
 * Converts a temperature value from Fahrenheit to Celsius.
 *
 * @param value - The temperature value in Fahrenheit.
 * @returns The converted temperature in Celsius.
 */
function toCelsius(value: number): number {
	return ((value - 32) * 5) / 9;
}

/**
 * Converts a temperature value from Celsius to Fahrenheit.
 *
 * @param value - The temperature value in Celsius.
 * @returns The converted temperature in Fahrenheit.
 */
function toFahrenheit(value: number): number {
	return (value * 9) / 5 + 32;
}

/**
 * Converts a distance value from miles to kilometers.
 *
 * @param value - The distance value in miles.
 * @returns The converted distance in kilometers.
 */
function toKilometers(value: number): number {
	return value / 0.621371;
}

/**
 * Converts a distance value from kilometers to miles.
 *
 * @param value - The distance value in kilometers.
 * @returns The converted distance in miles.
 */
function toMiles(value: number): number {
	return value * 0.621371;
}

export { getTemperatureConversion, getDistanceConversion };
