let routes = [];
let lastRoute = "";
export function setRoutes(appRoute) {
	routes = appRoute;
}

export function getRoutes() {
	return routes;
}

export function setLastRoute(route) {
	lastRoute = route;
}

export function getLastRoute() {
	return lastRoute;
}
