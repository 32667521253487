import { translateKeys, manualTranslations } from "./i18n"; // Adjust the path as necessary

// Function to get translations with manual overrides
export const getTranslations = async (keys: string[], targetLang: string) => {
	const automaticTranslations = await translateKeys(keys, targetLang);
	const finalTranslations: { [key: string]: string } = {};

	keys.forEach((key) => {
		// Use manual translation if it exists, otherwise use automatic translation
		finalTranslations[key] = manualTranslations[targetLang]?.[key] || automaticTranslations[key];
	});

	return finalTranslations;
};
