import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import { getTranslations } from "./TranslationService"; // Import the new function

i18n
	.use(initReactI18next) // Passes i18n down to react-i18next
	.init({
		lng: "en", // Default language
		fallbackLng: "en", // Fallback language
		interpolation: {
			escapeValue: false // React already does escaping
		},
		resources: {} // Initially empty, will be filled dynamically
	});

// Function to fetch automatic translations
export const translateText = async (text, targetLang) => {
	const response = await axios.post("YOUR_TRANSLATION_API_URL", {
		text,
		targetLang
	});
	return response.data.translatedText; // Adjust based on your API response
};

// Function to translate multiple keys
export const translateKeys = async (keys, targetLang) => {
	const translations = {};
	for (const key of keys) {
		translations[key] = await translateText(key, targetLang);
	}
	return translations;
};

// Function to manually override translations
export const manualTranslations = {
	en: {
		welcome: "Welcome",
		origin: "Origin",
		destination: "Destination"
	},
	es: {
		welcome: "Bienvenido",
		origin: "Origen",
		destination: "Destino"
	}
	// Add more languages and keys as needed
};

export default i18n;
