export const extractLocationDetails = (address) => {
	// Split the address by commas and trim whitespace
	const parts = address.split(",").map((part) => part.trim());

	// Initialize variables for city, state, and country
	let city = "";
	let state = "";
	let country = "";

	// Check the number of parts in the address
	if (parts.length > 0) {
		// If there's at least one part, assume the last part is the country
		country = parts.pop(); // Remove and store the last part as country

		// If there's at least one more part, assume the second last part is the state
		if (parts.length > 0) {
			state = parts.pop(); // Remove and store the second last part as state
		}

		// The remaining parts are considered as the city
		if (parts.length > 0) {
			city = parts.pop(); // Join remaining parts as city
		}
	}

	// Return an object with city, state, and country
	return { city, state, country };
};
