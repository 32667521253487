import { useEffect, useState } from "react";
import { API } from "./API";

let user: any = {};

export function setAuthUser(_user) {
	user = _user;
}

export function getAuthUser() {
	return user;
}

export function useAuthUser() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [authenticated, setAuthenticated] = useState(false);
	const [error, setError] = useState("");

	useEffect(() => {
		API("POST", "/user/me", {})
			.then((result: any) => {
				setData(result);
				setLoading(false);
				setAuthenticated(true);
				setError("");

				setAuthUser(result);
			})
			.catch((error) => {
				setError(error);
				setLoading(false);
				setAuthenticated(false);
			});
	}, []);
	return { data, authenticated, loading, error };
}
