import { useTranslation } from "react-i18next";

// Custom hook to provide translation function
const useTranslate = () => {
	const { t } = useTranslation();

	const translate = (key: string, options: { defaultValue?: string } = {}) => {
		return t(key, options); // Returns the translation string
	};

	return { translate };
};

export default useTranslate;
