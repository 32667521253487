import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// @ts-ignore
import { en, de, fr, pt, es, ja } from "@roambee/client-hc20";
// Initialize i18next
i18n.use(initReactI18next).init({
	resources: {
		en: { translation: en },
		de: { translation: de },
		pt: { translation: pt },
		es: { translation: es },
		fr: { translation: fr },
		ja: { translation: ja }
	},
	lng: "en", // Default language
	fallbackLng: "en", // Fallback language
	interpolation: {
		escapeValue: false // React already does escaping
	}
});

export default i18n;
