import { getAuthUser } from "./Auth";

interface Setting {
	value: string;
	category: string;
	setting: string;
}

interface User {
	settings: Setting[];
}

/**
 * Gets the user's preference setting value
 * @param category - The settings category
 * @param setting - The specific setting name
 * @returns The setting value or undefined if not found
 */
function getUserPreference(category: string, setting: string): string | undefined {
	try {
		const user: User | null = getAuthUser();
		// return user?.settings?.find((s) => s.category === category && s.setting === setting)?.value;
		// we have to do this because same object is getting repeated multiple times
		return user?.settings?.reduceRight((found: string | undefined, s: Setting) => {
			if (!found && s.category === category && s.setting === setting) {
				return s.value;
			}
			return found;
		}, undefined);
	} catch (error) {
		console.error("Failed to get user preference:", error);
		return undefined;
	}
}

/**
 * Gets the temperature unit based on user preference
 * @returns The temperature unit (°C or °F) or °C as default
 */
function getTemperatureUnit(): string {
	const tempPref = getUserPreference("PREFS", "temperature");
	return tempPref?.toLowerCase() === "fahrenheit" ? "°F" : "°C";
}

/**
 * Gets the distance unit based on user preference
 * @returns The distance unit (km or mi) or km as default
 */
function getDistanceUnit(): string {
	const distPref = getUserPreference("PREFS", "distance");
	return distPref?.toLowerCase() === "standard" ? "mi" : "km";
}

/**
 * Converts a temperature value based on the user's settings.
 *
 * @param value - The temperature value to convert (could be in Celsius or Fahrenheit).
 * @returns The converted temperature or a placeholder '-' if the value or settings are invalid.
 */
function getTemperatureConversion(value: number | null | undefined): number | string {
	try {
		// Validate the input value
		if (value === null || value === undefined || !Number.isFinite(value)) {
			console.warn("Temperature conversion failed: Input value is invalid or not a number");
			return "-";
		}

		// Get temperature preference
		const tempPref = getUserPreference("PREFS", "temperature");

		if (!tempPref) {
			console.info("Temperature conversion failed: Temperature preference not found");
			return value;
		}

		// Convert based on valid temperature setting
		switch (tempPref.toLowerCase()) {
			case "celsius":
				return value;
			case "fahrenheit":
				return toFahrenheit(value);
			default:
				console.warn(`Temperature conversion failed: Invalid temperature unit '${tempPref}'`);
				return value;
		}
	} catch (error) {
		console.error("Temperature conversion failed with error:", error);
		return "-";
	}
}

/**
 * Converts a distance value based on the user's settings.
 *
 * @param value - The distance value to convert (could be in kilometers or miles).
 * @returns The converted distance or a placeholder '-' if the value or settings are invalid.
 */
function getDistanceConversion(value: number | null | undefined): number | string {
	try {
		// Validate the input value
		if (value === null || value === undefined || !Number.isFinite(value)) {
			console.warn("Distance conversion failed: Input value is invalid or not a number");
			return "-";
		}

		// Get distance preference
		const distPref = getUserPreference("PREFS", "distance");

		if (!distPref) {
			console.info("Distance conversion failed: Distance preference not found");
			return value;
		}

		// Convert based on valid distance setting
		switch (distPref.toLowerCase()) {
			case "metric":
				return toKilometers(value);
			case "standard":
				return toMiles(value);
			default:
				console.warn(`Distance conversion failed: Invalid distance unit '${distPref}'`);
				return value;
		}
	} catch (error) {
		console.error("Distance conversion failed with error:", error);
		return "-";
	}
}

// TODO: this method might not be needed anymore, since by default data coming in celsius
/**
 * Converts a temperature value from Fahrenheit to Celsius.
 *
 * @param value - The temperature value in Fahrenheit.
 * @returns The converted temperature in Celsius.
 */
// function toCelsius(value: number): number {
// 	return Math.round((((value - 32) * 5) / 9) * 100) / 100;
// }

/**
 * Converts a temperature value from Celsius to Fahrenheit.
 *
 * @param value - The temperature value in Celsius.
 * @returns The converted temperature in Fahrenheit.
 */
function toFahrenheit(value: number): number {
	return Math.round((value * 9) / 5 + 32 * 100) / 100;
}

/**
 * Converts a distance value from meters to kilometers.
 *
 * @param value - The distance value in meters.
 * @returns The converted distance in kilometers.
 */
function toKilometers(value: number): number {
	return Math.round((value / 1000) * 100) / 100;
}

/**
 * Converts a distance value from meters to miles.
 *
 * @param value - The distance value in meters.
 * @returns The converted distance in miles.
 */
function toMiles(value: number): number {
	return Math.round((value / 1609.344) * 100) / 100;
}

export { getTemperatureConversion, getDistanceConversion, getTemperatureUnit, getDistanceUnit };
