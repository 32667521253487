import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

const BASE_URL = process.env.CLIENT_HC10_API_URL;

interface ErrorResponse {
	status: string;
	message: string;
}

export async function API<T>(
	method: AxiosRequestConfig["method"],
	api_path: string,
	body?: Record<string, any>, // Dynamic request body type
	base_url?: string
): Promise<T | string> {
	const options: AxiosRequestConfig = {
		method,
		headers: {
			"Content-Type": "application/json"
		},
		withCredentials: true,
		data: body,
		url: base_url ? base_url + api_path : BASE_URL + api_path
	};

	try {
		const response: AxiosResponse<T> = await axios(options);
		// return response.data;

		if (response.headers["content-type"].includes("application/json")) {
			return response.data as T;
		} else {
			return response.data as string; // Return non-JSON response data as string
		}
	} catch (error) {
		const axiosError = error as AxiosError<ErrorResponse | undefined>;
		const responseBody = axiosError.response.data;
		throw responseBody;
	}
}
