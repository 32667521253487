import { useEffect, useState } from "react";
import { API } from "./API";

let user: any = {};

export function setAuthUser(_user) {
	user = _user;
}

export function getAuthUser() {
	return user;
}

export function useAuthUser() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [authenticated, setAuthenticated] = useState(false);
	const [error, setError] = useState("");

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const result = await API("POST", "/user/me", {});
				setData(result);
				setAuthenticated(true);
				setAuthUser(result);
				setError("");
			} catch (err) {
				setError(err);
				setAuthenticated(false);
			} finally {
				setLoading(false);
			}
		};

		checkAuth();
	}, []);

	// Return initial values immediately, will be updated after API call
	return {
		data,
		authenticated,
		loading,
		error
	};
}
