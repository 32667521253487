import { DateTime } from "luxon";

// Function to format date from timestamp (in seconds or milliseconds)
const formatDateTime = (timestamp, format, timezoneId?) => {
	// Check if timestamp is null or undefined
	if (timestamp === null || timestamp === undefined) {
		return "-"; // No timestamp provided
	}
	let dateTime;
	// Determine if timestamp is in seconds or milliseconds
	if (timestamp.toString().length === 10) {
		if (timezoneId) {
			// Convert seconds to milliseconds with Time Zone
			dateTime = DateTime.fromSeconds(timestamp).setZone(timezoneId);
		} else {
			// Convert seconds to milliseconds
			dateTime = DateTime.fromSeconds(timestamp);
		}
	} else if (timestamp.toString().length === 13) {
		if (timezoneId) {
			// Convert milliseconds to seconds with Time Zone
			dateTime = DateTime.fromMillis(timestamp).setZone(timezoneId);
		} else {
			// Convert milliseconds directly
			dateTime = DateTime.fromMillis(timestamp);
		}
	} else {
		// Handle invalid timestamps or other cases
		return "-"; // Invalid timestamp
	}

	// Format DateTime object as needed
	if (dateTime.isValid && timezoneId && timezoneId !== "Asia/Calcutta") {
		return dateTime.toFormat(`${format} ZZZZ`);
	} else if (dateTime.isValid && timezoneId === "Asia/Calcutta") {
		return dateTime.toFormat(format).concat(" IST");
	} else if (dateTime.isValid && !timezoneId) {
		return dateTime.toFormat(format);
	} else {
		return "-"; // Invalid date
	}
};

const formatCustomDateTime = (timestamp, format, timezoneId?) => {
	// Function to check if the input is a Unix timestamp
	const isUnixTimestamp = (value) => {
		return /^\d+$/.test(value);
	};

	// Convert input to Unix timestamp if it is in ISO 8601 format
	const convertToUnixTimestamp = (value) => {
		if (isUnixTimestamp(value)) {
			// If it is already a Unix timestamp, return it
			return parseInt(value, 10);
		} else {
			// Otherwise, convert the ISO 8601 string to Unix timestamp
			const dateTime = DateTime.fromISO(value, { zone: "UTC" });
			return dateTime.ts;
		}
	};

	const unixTimestamp = timestamp ? convertToUnixTimestamp(timestamp.slice(0, -1).toUpperCase()) : null;

	let dateTime;
	// Determine if timestamp is in seconds or milliseconds
	if (unixTimestamp && unixTimestamp.toString().length === 10) {
		if (timezoneId) {
			// Convert seconds to milliseconds with Time Zone
			dateTime = DateTime.fromSeconds(unixTimestamp).setZone(timezoneId);
		} else {
			// Convert seconds to milliseconds
			dateTime = DateTime.fromSeconds(unixTimestamp);
		}
	} else if (unixTimestamp && unixTimestamp.toString().length === 13) {
		if (timezoneId) {
			// Convert milliseconds to seconds with Time Zone
			dateTime = DateTime.fromMillis(unixTimestamp).setZone(timezoneId);
		} else {
			// Convert milliseconds directly
			dateTime = DateTime.fromMillis(unixTimestamp);
		}
	} else {
		// Handle invalid timestamps or other cases
		return "-"; // Invalid timestamp
	}
	// Format DateTime object as needed
	if (dateTime.isValid && timezoneId && timezoneId !== "Asia/Calcutta") {
		return dateTime.toFormat(`${format} ZZZZ`);
	} else if (dateTime.isValid && timezoneId === "Asia/Calcutta") {
		return dateTime.toFormat(format).concat(" IST");
	} else if (dateTime.isValid && !timezoneId) {
		return dateTime.toFormat(format);
	} else {
		return "-"; // Invalid date
	}
};

const formatDuration = (duration: number): string => {
	// Convert to seconds if in milliseconds
	const seconds = duration.toString().length === 13 ? Math.floor(duration / 1000) : duration;

	const years = Math.floor(seconds / (365 * 24 * 60 * 60));
	const months = Math.floor((seconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
	const days = Math.floor((seconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
	const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
	const minutes = Math.floor((seconds % (60 * 60)) / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	const parts = [];
	if (years > 0) parts.push(`${years} ${years === 1 ? "year" : "years"}`);
	if (months > 0) parts.push(`${months} ${months === 1 ? "month" : "months"}`);
	if (days > 0) parts.push(`${days} ${days === 1 ? "day" : "days"}`);
	if (hours > 0) parts.push(`${hours} ${hours === 1 ? "hour" : "hours"}`);
	if (minutes > 0) parts.push(`${minutes} ${minutes === 1 ? "min" : "mins"}`);
	if (remainingSeconds > 0) parts.push(`${remainingSeconds} ${remainingSeconds === 1 ? "sec" : "secs"}`);

	return parts.length > 0 ? parts.join(" ") : "0 secs";
};

/**
 * Calculates and formats the age duration for assets grid based on first seen timestamp
 * @param firstSeen - Timestamp in seconds or milliseconds
 * @param timezone - Timezone identifier (default: "Asia/Calcutta")
 * @returns Formatted duration string or "-" if invalid
 */
const formatAgeDuration = (firstSeen: number | string, timezone = "Asia/Calcutta"): string => {
	if (!firstSeen || Number.isNaN(Number(firstSeen))) {
		return "-";
	}

	const firstSeenMillis = typeof firstSeen === "string" ? (firstSeen.length === 10 ? Number(firstSeen) * 1000 : Number(firstSeen)) : firstSeen.toString().length === 10 ? firstSeen * 1000 : firstSeen;
	// Validate timezone
	let now, firstSeenTime;
	try {
		now = DateTime.now().setZone(timezone);
		firstSeenTime = DateTime.fromMillis(firstSeenMillis).setZone(timezone);
	} catch (error) {
		console.error(`Invalid timezone: ${timezone}`);
		return "-";
	}

	if (!now || !firstSeenTime) return "-";

	const ageDuration = now.diff(firstSeenTime, ["days", "hours", "minutes", "seconds"]);

	// Convert duration to seconds for formatDuration
	const totalSeconds = Math.floor(ageDuration.days * 24 * 60 * 60 + ageDuration.hours * 60 * 60 + ageDuration.minutes * 60 + ageDuration.seconds);
	return formatDuration(totalSeconds);
};

export { formatDateTime, formatCustomDateTime, formatDuration, formatAgeDuration };
